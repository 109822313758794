

<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 75px; right: -35px; border-radius: 8px;"
      @click="toggleDarkMode"
    >
      <v-icon large>
        mdi-theme-light-dark
      </v-icon>
    </v-card>

    <!--<v-menu-->
      <!--v-model="menu"-->
      <!--:close-on-content-click="false"-->
      <!--activator="#settings"-->
      <!--bottom-->
      <!--content-class="v-settings"-->
      <!--left-->
      <!--nudge-left="8"-->
      <!--offset-x-->
      <!--origin="top right"-->
      <!--transition="scale-transition"-->
    <!--&gt;-->
      <!--<v-card-->
        <!--class="text-center mb-0"-->
        <!--width="300"-->
      <!--&gt;-->
        <!--<v-card-text>-->

          <!--<v-row-->
            <!--align="center"-->
            <!--no-gutters-->
          <!--&gt;-->
            <!--<v-col cols="auto">-->
              <!--Dark Mode-->
            <!--</v-col>-->

            <!--<v-spacer />-->

            <!--<v-col cols="auto">-->
              <!--<v-switch-->
                <!--v-model="$vuetify.theme.dark"-->
                <!--class="ma-0 pa-0"-->
                <!--color="secondary"-->
                <!--hide-details-->
              <!--/>-->
            <!--</v-col>-->
          <!--</v-row>-->

          <!--<v-divider class="my-4 secondary" />-->

          <!--<v-row-->
            <!--align="center"-->
            <!--no-gutters-->
          <!--&gt;-->
            <!--<v-col cols="auto">-->
              <!--Sidebar Mini-->
            <!--</v-col>-->

            <!--<v-spacer />-->

            <!--<v-col cols="auto">-->
              <!--<v-switch-->
                <!--v-model="internalValue"-->
                <!--class="ma-0 pa-0"-->
                <!--color="secondary"-->
                <!--hide-details-->
              <!--/>-->
            <!--</v-col>-->
          <!--</v-row>-->

        <!--</v-card-text>-->
      <!--</v-card>-->
    <!--</v-menu>-->
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    data: () => ({
      menu: false,
      scrim: 'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      scrims: [
        'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
        'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
        'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
      ],
    }),
    props: {
      envSelected: {
        type: Object
      }
    },

    created(){
      if(this.envSelected && this.envSelected.color){
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = this.envSelected.color.code;
      }
    },

    watch: {
      envSelected( val) {
        if(val && val.color){
          this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val.color.code;
        }
      },
      "$vuetify.theme.dark"(val){
        if(this.envSelected && this.envSelected.color){
          this.$vuetify.theme.themes[val ? 'dark' : 'light'].primary = this.envSelected.color.code;
        }
      },
      scrim (val) {
        this.$store.commit('SET_SCRIM', val)
      },
    },

    methods: {
      toggleDarkMode: function(){
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      }
    }

  }
</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
